import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from "@remix-run/react";

import type { MetaFunction, LoaderFunction } from "@remix-run/cloudflare";

import "./tailwind.css";
import "./global.css";

import { rootAuthLoader } from "@clerk/remix/ssr.server";
import { ClerkApp } from "@clerk/remix";

export const loader: LoaderFunction = (args) => rootAuthLoader(args);

export const meta: MetaFunction = () => [
  {
    charset: "utf-8",
  },
  {
    title: "Braid Social | How well do your friends know you?",
  },
  {
    name: "description",
    content: "Create and take quizzes to see how well your friends know you!",
  },
  {
    name: "viewport",
    content: "width=device-width,initial-scale=1",
  },
];

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
        <link rel="icon" href="/favicon.png" type="image/png" />
      </head>
      <body className="h-full w-full bg-white font-nunito">
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

export function ErrorBoundary() {
  const error = useRouteError();

  return (
    <html lang="en">
      <head>
        <title>Braid Social | Oh no! Something went wrong</title>
        <Meta />
        <Links />
      </head>
      <body>
        <Layout>
          <div className="flex flex-col justify-center items-center text-3xl h-screen">
            <div className="text-center px-4">
              💥 Oops, looks like something went wrong
            </div>
            <p className="text-sm my-4">
              We've been alerted and are working on it!
            </p>
            <code className="text-sm bg-gray-50 rounded py-2 px-2 max-w-[300px] break-words">
              {error.message}
            </code>
          </div>
        </Layout>
        <Scripts />
      </body>
    </html>
  );
}

export default ClerkApp(App);
